import React from "react"
import PCFLogo from '../graphics/pcf-logo.svg'

const Logo = () => {
  return (
    <PCFLogo />
  )
}

export default Logo
